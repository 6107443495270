<template>
  <div>
    <div class="cbampage">
      <div class="cbam_ban">
        <img src="./assets/Background.png" class="img-responsive" alt="CBAM（欧盟碳关税）服务平台" />
        <div class="cbamInfo">
          <div class="wrap">
            <p class="company">中达信（杭州）科技有限公司</p>
            <h1>CBAM (碳边境调节机制) 一站式咨询服务</h1>
            <h3>快速提交合规碳排放报告！专业！省时！省钱！（来电咨询免费送CBAM申报服务）</h3>
            <div class="more1">
              <a
                ><p style="position: absolute; font-size: 15px">欢迎来电，碳资产免费咨询</p>
                <span><img alt="tell" src="./assets/tell.svg" class="tell" /></span>4000909022</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="cbam_main_abg">
        <div class="wapper1">
          <div class="cbam_main_a">
            <div class="tit2">
              <h2>CBAM（欧盟碳关税）是什么？</h2>
            </div>
            <div class="short-info">
              <p>
                CBAM法规，即欧盟碳边境调节机制(Carbon Border Adjustment Mechanism)，俗称“碳关税”，是欧盟对进口的特定商品征收碳排放费用。
              </p>
              <p>
                该法规以《欧洲议会和欧盟理事会关于建立碳边境调节机制的第2023/956号条例》(Regulation(EU)2023/956)于2023年5月17日正式生效，并于2023年10月1日开始实施。
              </p>
              <p>
                CBAM法规是欧盟“绿色新政(Green Deal)”下“Fit for 55”一揽子计划的重要组成部分，旨在防止碳泄露，补充和加强欧盟碳市场(EU
                ETS)运作，帮助欧盟更好地实现欧盟气候目标。
              </p>
              <p>CBAM法规覆盖范围主要有水泥、电力、化肥、氢气、钢铁和铝六大类商品。</p>
            </div>
            <div></div>
            <div class="list">
              <div class="cbam_main_a_lt">
                <div class="img">
                  <img src="./assets//chuan.jpg" class="img1" alt="CBAM（欧盟碳关税）填报服务" />
                  <!-- <img src="./assets/c1_img2.png" class="img2" alt="碳边境调节机制" /> -->
                </div>
              </div>
              <div class="cbam_main_a_rt">
                <div class="nr_box">
                  <h3>实施时间段</h3>
                  <div class="h3">CBAM法规于2023年10月1日开始实施，分为过渡期和征收期</div>
                  <div class="h4">
                    <dl>
                      <dt>过渡期（2023年10月1日~2025年12月31日）</dt>
                      <dd>
                        <p>提交CBAM报告，不计算碳排放费用</p>
                        <p><font>注意：第一份报告提交截止时间：2024年1月31日（提交2023年第四季度报告）</font></p>
                      </dd>
                    </dl>
                    <dl>
                      <dt>征税期（2026年1月1日起）</dt>
                      <dd>
                        <p>提交CBAM申报，须计算碳排放费用</p>
                        <p>强制认证要求</p>
                      </dd>
                    </dl>
                  </div>
                  <div class="more1 more-reset">
                    <a
                      ><p style="position: absolute; font-size: 15px">欢迎来电，碳资产免费咨询</p>
                      <span><img alt="tell" src="./assets/tell.svg" class="tell" /></span>4000909022</a
                    >
                  </div>
                </div>
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="cbam_main_bbg">
        <div class="wapper1">
          <div class="cbam_main_b">
            <div class="tit2">
              <h2>您还在为如何满足CBAM报送合规要求而苦恼吗？</h2>
            </div>
            <div class="list">
              <ul>
                <li>
                  <div class="nr_box">
                    <div class="txtbg">
                      <div class="txt">
                        <div class="icon-box"><img src="./assets/doctor.png" alt="" style="height: 100%" /></div>
                        <h3>专业门槛高</h3>
                        <div class="h3">
                          通篇英文专业名词，理解难度大，专业门槛高。“低位发热量、阳极类型、嵌入式排放“等词汇，让母语人士都望而却步。
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="nr_box">
                    <div class="txtbg">
                      <div class="txt">
                        <div class="icon-box"><img src="./assets/money.png" alt="" style="height: 100%" /></div>
                        <h3>成本高昂周期长</h3>
                        <div class="h3">常态化填报下，依赖咨询机构或额外聘请填报人员，大大增加企业成本，并且培养周期较长。</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="nr_box">
                    <div class="txtbg">
                      <div class="txt">
                        <div class="icon-box"><img src="./assets/data.png" alt="" style="height: 100%" /></div>
                        <h3>数据搜集难度大</h3>
                        <div class="h3">数据填报量庞大，数据种类繁多，解析难度高，大部分数值难以找到对应相关数据。</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="nr_box">
                    <div class="txtbg">
                      <div class="txt">
                        <div class="icon-box"><img src="./assets/lack-data.png" alt="" style="height: 100%" /></div>
                        <h3>部分数据缺失无法填</h3>
                        <div class="h3">
                          多数企业没有碳排放数据库，无法收集到全量数据，也无法找到缺省值。数据缺失后影响填报通过及今后缴费额度。
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="next-arraw"><img src="./assets/arrow.png" alt="" width="100%" /></div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="cbam_main_c_box">
        <div class="tit2">
          <h2>中达信提供一站式全流程CBAM专业服务</h2>
        </div>
        <div class="solution"><img src="./assets/图片1.png" alt="" width="100%ß" /></div>
      </div>
      <div class="cbam_main_dbg">
        <div class="wapper1">
          <div class="cbam_main_d">
            <div class="tit2">
              <h2>全程专家服务，节省60%时间！</h2>
              <div class="h3">
                通过数字化方式，为企业提供碳数据填报、碳数据核算、碳数据报送、碳数据认证、咨询培训等全流程服务。可极大减少企业碳排放报送门槛，助力企业低成本合规报送碳排放数据。
              </div>
            </div>
            <div style="margin-top: 50px"><img src="./assets/系统.png" alt="" width="100%ß" /></div>
            <div class="more6" style="margin-top: 50px">
              <a><img alt="tell" src="./assets/tell.svg" class="tell" />4000909022</a>
            </div>
          </div>
        </div>
      </div>
      <div class="cbam_main_ebg">
        <div class="wapper1">
          <div class="cbam_main_e">
            <div class="tit2">
              <h2>常见碳数据填报问题</h2>
            </div>
            <div class="list">
              <ul>
                <li>
                  <div class="txtbg">
                    <div class="txt">
                      <div class="h2">01</div>
                      <h3>CBAM过渡期，有固定申报期间吗？</h3>
                      <div class="h4">
                        <strong>答:有固定的申报时间。</strong><br />
                        在过渡期，CBAM申报人（欧盟进口商）应在每季结束后一个月内提交CBAM报告。<br />
                        1、首次碳排放报告应于2024年1月31日前提交。<br />
                        2、第二次碳排放报告提交时间点是2024年4月30日前。<br />
                        3、第三次碳排放报告提交时间点是2024年7月31日前，以此类推报告提交时间。
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="txtbg">
                    <div class="txt">
                      <div class="h2">02</div>
                      <h3>向谁报送报告？</h3>
                      <div class="h4">
                        <strong>答:由授权申报人</strong>
                        (欧盟的进口商或间接海关代表）向欧盟CBAM主管机关报送报告，登录到欧盟过渡登记系统中，提交报告。<br />
                        但授权申报人为了填写该报告，会向产品供应商要求提供相应的碳排放数据去做登录，所以为了顺利出口，建议相关出口商提前做好准备。
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="txtbg">
                    <div class="txt">
                      <div class="h2">03</div>
                      <h3>碳排放数据缺失是否能用缺省值？</h3>
                      <div class="h4">
                        <strong>答:可以。</strong><br />
                        当申报人无法充分确定实际排放量时，应采取缺省值。缺省值原则上采用出口国对应产品（电力除外）的平均碳排放强度叠加一定比例的增幅。当某类产品出口国信息难以应用于某类货物时，缺省值应为欧盟碳市场该类产品表现最差的X%的设备的平均碳排放强度。
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="txtbg">
                    <div class="txt">
                      <div class="h2">04</div>
                      <h3>CBAM碳排放报告有误，可以修正吗？</h3>
                      <div class="h4">
                        <strong>答:可以修正。</strong><br />
                        每季结束后2个月内可以修正CBAM报告。而过渡期前两次的报告有缓冲空间，可在第三季报告截止日前修正。
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="txtbg">
                    <div class="txt">
                      <div class="h2">05</div>
                      <h3>CBAM过渡期，申报人若没有如期申报或虚伪申报，会有处罚吗？</h3>
                      <div class="h4">
                        <strong>答:会被处罚。</strong><br />
                        申报人若未如期申报或虚伪申报，按每吨碳排放量10-50欧元进行处罚。
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <p>公司地址：浙江省杭州市西湖区西溪首座5号楼629室D-33</p>
      <img src="./assets/police.png" alt="" /><a href="https://beian.miit.gov.cn/" target="_blank">浙公网安备 浙ICP备2024074285号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
a:hover,
a:focus {
  text-decoration: none !important;
}
.short-info {
  padding: 20px;
}
.short-info p {
  font-size: 23px;
}
.footer {
  background-color: #0c2258;
  padding: 30px 100px;
  color: #aeaaaa;
  text-align: center;
}

.footer a {
  color: #aeaaaa;
}
.footer img {
  width: 26px;
  margin-top: -9px;
}
</style>
