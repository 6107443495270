import $ from "./jquery.min";
$(function () {
  /*var winHig = $(window).height()/2 - $('#ads').height()/2;
	$("#ads").css({"left":0,"top":-winHig*2});
	$("#ads").show();
	$("#ads").animate({"left":0,"top":0},500);
    if($.cookie("isClose") == 'yes'){
    	$("#ads").hide();
    }
    $("#ads-close").click(function(){
    	$("#ads").hide();
    	$.cookie("isClose",'yes',{ expires:1/8640});	//测试十秒
    	//$.cookie("isClose",'yes',{ expires:1});		//一天
    });*/

  $(".menu-btn").click(function () {
    $(".sideMenu").slideToggle();
    $(".side_bg").toggle();
    $(this).toggleClass("active");
  });
  $(".closed,.side_bg").click(function () {
    $(".sideMenu,.side_bg").fadeOut();
    $(".menu-btn").removeClass("active");
  });

  $(window).scroll(function () {
    var sc = $(window).scrollTop();
    if (sc > 90) {
      $(".header").addClass("header-posi");
    } else {
      $(".header").removeClass("header-posi");
    }
    if (sc > 300) {
      $(".sider").addClass("active");
    } else {
      $(".sider").removeClass("active");
    }
  });
  init();
  $(".xiUl li").each(function () {
    $(this).click(function () {
      $(this).addClass("on").siblings().removeClass("on");
    });
  });
});

function init() {
  var $w = $(window).width();
  if ($w > 1000) {
    $(".sideMenu").removeClass("active");
  } else {
    $(".faUl").addClass("faUl_mob");
    $(".faUl li h3").click(function () {
      $(this).siblings(".links").slideToggle();
    });
  }
  $(".menu li").each(function () {
    var ileft = $(this).offset().left;
    $(this).find(".sub_menu").css("padding-left", ileft);
  });
}
$(window).resize(function () {
  init();
});

!(function (window) {
  /* 设计图文档宽度 */
  var mobileDocWidth = 750;
  var pcDocWidth = 1920;

  var doc = window.document,
    docEl = doc.documentElement,
    resizeEvt = "orientationchange" in window ? "orientationchange" : "resize";

  var recalc = (function refreshRem() {
    var clientWidth = docEl.getBoundingClientRect().width;
    docEl.style.fontSize =
      Math.min((clientWidth / (clientWidth < pcDocWidth && clientWidth > mobileDocWidth ? pcDocWidth : mobileDocWidth)) * 100, 100) + "px";

    return refreshRem;
  })();

  /* 添加倍屏标识，安卓倍屏为1 */
  docEl.setAttribute("data-dpr", window.navigator.appVersion.match(/iphone/gi) ? window.devicePixelRatio : 1);

  if (/iP(hone|od|ad)/.test(window.navigator.userAgent)) {
    /* 添加IOS标识 */
    doc.documentElement.classList.add("ios");
    /* IOS8以上给html添加hairline样式，以便特殊处理 */
    if (parseInt(window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)[1], 10) >= 8) {
      doc.documentElement.classList.add("hairline");
    }
  }

  var ua = navigator.userAgent.toLowerCase();
  //判断是不是在iPhone的Safair浏览器打开的本页面
  if (
    ua.indexOf("applewebkit") > -1 &&
    ua.indexOf("mobile") > -1 &&
    ua.indexOf("safari") > -1 &&
    ua.indexOf("linux") === -1 &&
    ua.indexOf("android") === -1 &&
    ua.indexOf("chrome") === -1 &&
    ua.indexOf("ios") === -1 &&
    ua.indexOf("browser") === -1
  ) {
    document.querySelector("html").className += " safair"; //在原来的后面加这个
  }

  if (!doc.addEventListener) return;
  window.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener("DOMContentLoaded", recalc, false);
})(window);
